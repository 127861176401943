<template>
    <div class="remnantDust">
        <div class="el_left businessFore">
            <table>
                <tbody>
                    <tr>
                        <th>发货单号：</th>
                        <td>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>车次：</th>
                        <td>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>强度：</th>
                        <td>
                            <el-select v-model="value" placeholder="请选择">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </td>
                    </tr>
                    <tr>
                        <th>特性要求：</th>
                        <td>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>工程名称：</th>
                        <td>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>发货方量：</th>
                        <td>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>签收方量：</th>
                        <td>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>过磅方量：</th>
                        <td>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>盘盈方量：</th>
                        <td>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>发货方量：</th>
                        <td>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>签收方量：</th>
                        <td>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>发货方量：</th>
                        <td>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>处置方式：</th>
                        <td>
                            <el-radio v-model="radio" label="1">
                                倒掉
                            </el-radio>
                            <el-radio v-model="radio" label="2">
                                分离
                            </el-radio>
                            <el-radio v-model="radio" label="3">
                                转发
                            </el-radio>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="el_left businessTable">
            <div class="table-bg">
                <el-table
                    :data="tableData6"
                    stripe
                    style="width: 100%;"
                >
                    <el-table-column
                        prop="id"
                        label="ID"
                        width="180"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="姓名"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="amount1"
                        label="数值 1（元）"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="amount2"
                        label="数值 2（元）"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="amount3"
                        label="数值 3（元）"
                    >
                    </el-table-column>
                </el-table>
            </div>
            <div>
                <p>处理结果：</p>
                <textarea
                    placeholder="请输入内容"
                ></textarea>
            </div>
            <div>
                <p>异常原因：</p>
                <textarea
                    placeholder="请输入异常原因"
                ></textarea>
            </div>
        </div>
        <footer class="el_left">
            <button class="save" @click="save">
                保存
            </button>
            <button class="Close" @click="Close">
                关闭
            </button>
        </footer>
    </div>
</template>

<script>
export default {
    // 退剩灰弹出层页
    name: 'remnant-dust',
    components: {},
    props: {},
    data() {
        return {
            input: '',
            options: [{
                value: '选项1',
                label: '黄金糕',
            }, {
                value: '选项2',
                label: '双皮奶',
            }, {
                value: '选项3',
                label: '蚵仔煎',
            }, {
                value: '选项4',
                label: '龙须面',
            }, {
                value: '选项5',
                label: '北京烤鸭',
            }],
            value: '',
            radio: '',
            tableData6: [{
                id: '12987122',
                name: '王小虎',
                amount1: '234',
                amount2: '3.2',
                amount3: 10,
            }, {
                id: '12987123',
                name: '王小虎',
                amount1: '165',
                amount2: '4.43',
                amount3: 12,
            }, {
                id: '12987124',
                name: '王小虎',
                amount1: '324',
                amount2: '1.9',
                amount3: 9,
            }, {
                id: '12987125',
                name: '王小虎',
                amount1: '621',
                amount2: '2.2',
                amount3: 17,
            }, {
                id: '12987126',
                name: '王小虎',
                amount1: '539',
                amount2: '4.1',
                amount3: 15,
            }],
        };
    },
    watch: {},
    computed: {},
    methods: {
        save() {

        },
        Close() {

        },
    },
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.remnantDust
    width 100%
    height 100%
    padding 0.2rem 0.25rem
    overflow hidden
    >div
        width calc(50% - 0.5rem)
        height calc(100% - 0.6rem)
        margin 0 0.25rem
    .businessFore
        table
            width 100%
            height 100%
            th
                font-size 0.16rem
                color #333333
                font-weight 400
                text-align left
                width 1rem
            td
                .el-select
                    width 100%
    .businessTable
        .table-bg
            height 3.3rem
            margin-bottom 0.2rem
        >div
            overflow hidden
            margin-bottom 0.2rem
            p,textarea
                float left
            p
                width 1rem
                color #333
                font-size 0.16rem
                line-height 0.36rem
            textarea
                width: calc(100% - 1rem);
                resize: none;
                height: 1.4rem;
                border: 1px solid #d7d7d7;
                padding: 0 0.14rem;
                font-size: 0.16rem;
                line-height: 0.36rem;
    footer
        width 100%
        margin-top 0.2rem
        height 0.4rem
        text-align center
        button
            width 0.76rem
            height 0.4rem
            font 0.2rem '微软雅黑'
            border-radius 2px
            margin 0 0.15rem
            border none
            cursor pointer
            &.save
                color #fff
                background #1577D2
            &.Close
                color #1577D2
                background #fff
                border 1px solid #1577D2
</style>